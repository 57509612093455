import { template as template_b6911d28e0fc40098ba7d6684ddb1bf1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_b6911d28e0fc40098ba7d6684ddb1bf1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
