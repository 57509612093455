import { template as template_8548c9e644f5406488968b4197722e50 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8548c9e644f5406488968b4197722e50(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
