import { template as template_78efe0139ca8497f92caba5f678c3e43 } from "@ember/template-compiler";
const FKLabel = template_78efe0139ca8497f92caba5f678c3e43(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
