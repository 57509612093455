import { template as template_2ea3b6a72fdc4d20b3a1ad038f83df0d } from "@ember/template-compiler";
const WelcomeHeader = template_2ea3b6a72fdc4d20b3a1ad038f83df0d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
